<template>
  <input :value="value" @input="onInput" @focus="onFocus" @blur="onBlur" :type="type" class="form-control" v-bind="$attrs">
</template>

<script>
export default {
  name: 'RsInput',
  inheritAttrs: false,
  props: {
    value: {},
    type: { type: String, default: 'text' }
  },
  methods: {
    onInput (e) {
      this.$emit('input', e.target.value)
      // 通知父组件校验
      this.$parent.$emit('validate')
    },
    onFocus () {
      this.$parent.$emit('inputOnFocus')
    },
    onBlur (e) {
      this.$parent.$emit('inputOnBlur', e.target.value)
    }
  }
}
</script>
