import request from '@/utility/request'

function index (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STATEMENTS_INDEX, data).then(data => {
    callback(data)
  })
}
function insert (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STATEMENTS_INSERT, data).then(data => {
    callback(data)
  })
}
function edit (data, callback) {
  return request.get(process.env.VUE_APP_API_FINANCE_STATEMENTS_EDIT, { params: data }).then(data => {
    callback(data)
  })
}
function update (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STATEMENTS_UPDATE, data).then(data => {
    callback(data)
  })
}
function del (data, callback) {
  return request.delete(process.env.VUE_APP_API_FINANCE_STATEMENTS_DELETE, { params: data }).then(data => {
    callback(data)
  })
}
export default {
  index, insert, edit, update, del
}
