<template>
  <div class="form-group bmd-form-group"
  :class="{'has-danger':isDanger,'has-success':isSuccess,'is-focused':isFocused, 'is-filled':isFilled}">
    <label class="bmd-label-floating" v-if="label">{{label}}</label>
    <slot></slot>
    <span class="form-control-feedback" :class="icon" @click="iconClick" v-if="iconAttr">
      <i class="material-icons">{{iconAttr}}</i>
    </span>
    <span class="error" v-if="error">
      {{error}}
    </span>
  </div>
</template>
<script>
import Schema from 'async-validator'
export default {
  name: 'RsFormItem',
  inject: ['form'],
  props: {
    label: { type: String, default: '' },
    prop: { type: String },
    rules: { type: Array, default: Array },
    icon: { type: String, default: '' }
  },
  data () {
    return {
      error: '',
      isDanger: false,
      isSuccess: false,
      isFocused: false,
      isFilled: false,
      iconAttr: this.icon
    }
  },
  mounted () {
    if (this.prop) {
      // 监听自己触发验证事件 无prop不验证
      this.$on('validate', () => {
        this.validate()
      })
    }
    // 监听input点击事件
    this.$on('inputOnFocus', () => {
      this.isFocused = true
    })
    // 监听input离开事件
    this.$on('inputOnBlur', (e) => {
      this.isFocused = false
      if (e) {
        this.isFilled = true
      } else {
        this.isFilled = false
      }
    })
    // 检测子元素是否有值
    if (this.$children[0].value) {
      this.isFilled = true
    }
  },
  methods: {
    validate () {
      // 校验
      // eslint-disable-next-line no-eval
      const value = eval('this.form.model.' + this.prop)
      // 校验规则
      const schema = new Schema({ [this.prop]: this.rules })
      // 校验返回Promise
      return schema.validate({ [this.prop]: value }, errors => {
        if (errors) {
          this.isDanger = true
          this.isSuccess = false
          this.iconAttr = 'clear'
          this.error = errors[0].message
        } else {
          this.isDanger = false
          this.isSuccess = true
          this.iconAttr = 'done'
          this.error = ''
          this.isFocused = true
        }
      })
    },
    iconClick () {
      this.$emit('iconClick')
    }
  }
}
</script>

<style>
.search{
  pointer-events: auto;
}
</style>
