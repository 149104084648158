export default [
  {
    id: 2,
    path: '/custom',
    name: '客户',
    icon: 'person',
    index: true,
    component: () => import('@/views/layout/home.vue'),
    redirect: '/custom/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/custom/index.vue')
      },
      {
        path: 'details',
        component: () => import('@/views/custom/details.vue')
      }
    ]
  }
]
