<template>
<div class="card">
    <div class="card-header card-header-tabs card-header-info">
        <div class="nav-tabs-navigation">
            <div class="nav-tabs-wrapper">
                <span class="nav-tabs-title">{{title}}:</span>
                <ul class="nav nav-tabs" data-tabs="tabs">
                    <li v-for="(item, index) in tabPane" class="nav-item" :key="index">
                        <a class="nav-link" :href="'#'+item.name" :class="{active:value===item.name}" @click="handle(item.name)">
                            <i class="material-icons">{{item.icon}}</i>{{item.label}}
                        <div class="ripple-container"></div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card-body" style="overflow: auto;height: 100%;">
        <div class="tab-content">
            <slot></slot>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'RsTabs',
  props: {
    title: {
      type: String,
      default: '标题'
    },
    value: {
      type: String
    }
  },
  data () {
    return {
      tabPane: []
    }
  },
  mounted () {
    this.tabPane = this.$children
  },
  methods: {
    handle (e) {
      this.$emit('input', e)
    }
  }
}
</script>
<style scoped>
.card .card-body{
    position: unset;
}
</style>
