<template>
<div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th v-for="(item,index) in column" :key="index">{{item}}</th>
        <th v-if="isSearch" class="text-right">
          <input v-model="search" @keydown.enter="down" class="form-control"/>
        </th>
        <th v-else class="text-right">操作</th>
      </tr>
    </thead>
    <tbody>
      <!-- 存在分页数据，就使用分页下的列表数据，否则就直接使用当前 -->
        <tr v-for="(value, key) in data.current_page?data.data:data" :key="key">
          <td v-for="(v, k) in column" :key="k">{{value[k]}}</td>
          <slot :row="value" :column="key"></slot>
        </tr>
        <slot name="count"></slot>
    </tbody>
  </table>
  <!-- 分页部分 -->
  <rs-row v-if="data.current_page">
    <rs-col :sm="2" :md="2" :lg="2">
        <div>
            第
            {{ data.per_page * ( data.current_page -1 ) +1 }}
            -
            {{data.current_page == data.last_page ? data.total : data.per_page * ( data.current_page *1 )}}
            共{{data.total}}条
        </div>
    </rs-col>
    <rs-col :sm="10" :md="10" :lg="10">
      <ul class="pagination">
        <li class="page-item" :class="{'disabled': data.current_page == 1}">
            <a href="javascript:void(0)" class="page-link" @click="set_page(data.current_page - 1)">Prev</a>
        </li>
        <template v-for="count in page_index">
          <li v-if="count==null" :key="count">...</li>
          <li v-else :key="count"  class="page-item" :class="{'active':count==data.current_page}">
            <a class="page-link" href="javascript:void(0)" @click="set_page(count)">{{count}}</a>
          </li>
        </template>
        <li class="page-item" :class="{'disabled': data.current_page == data.last_page}">
            <a href="javascript:void(0)" class="page-link" @click="set_page(data.current_page + 1)">Next</a>
        </li>
      </ul>
    </rs-col>
  </rs-row>
</div>
</template>

<script>
export default {
  name: 'RsTableStriped',
  props: {
    column: {
      type: Object,
      require: true
    },
    data: {
      require: true
    },
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    page_index () {
      const currentPage = this.data.current_page
      const lastPage = this.data.last_page
      const item = []
      // 总页大于10
      if (lastPage > 10) {
        // 页面在前6页时
        if (currentPage <= 6) {
          for (let i = 1; i <= 10; i++) {
            item.push(i)
          }
          item.push(null)
          item.push(lastPage)
        // 在后6页时
        } else if (currentPage >= lastPage - 5) {
          item.push(1)
          item.push(null)
          for (let i = lastPage - 10; i < lastPage; i++) {
            item.push(i)
          }
          item.push(lastPage)
        } else {
          item.push(1)
          let flag = true
          if (currentPage >= 8 && flag) {
            item.push(null)
            flag = false
          }
          for (let i = currentPage - 5; i < currentPage + 5; i++) {
            item.push(i)
          }
          flag = true
          if (flag && currentPage <= lastPage - 5) {
            item.push(null)
          }
          item.push(lastPage)
        }
      } else {
        for (let i = 1; i <= lastPage; i++) {
          item.push(i)
        }
      }
      return item
    }
  },
  methods: {
    down() {
      this.$emit('search', this.search, 1)
    },
    set_page(page) {
      this.$emit('search', this.search, page)
    }
  }
}
</script>
