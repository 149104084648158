import Swal from 'sweetalert2'
function del (callback) {
  Swal.fire({
    type: 'warning',
    title: '确认删除?',
    text: '删除后无法恢复!',
    confirmButtonText: '确定',
    showCancelButton: true,
    cancelButtonText: '取消',
    focusConfirm: true,
    reverseButtons: true
  }).then((isConfirm) => {
    try {
      if (isConfirm.value) {
        callback()
      } else {
        Swal.fire('取消了', '操作失败!', 'error')
      }
    } catch (e) {
      console.log(e)
    }
  })
}
function success () {
  Swal.fire('成功', '点击了确定', 'success')
}
export default {
  del,
  success
}
