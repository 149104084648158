import Vue from 'vue'
import Notifications from 'vue-notification'
import 'sweetalert2/src/sweetalert2.scss'

Vue.use(Notifications)

function toast (title = '标题', text = '提示文字', type = 'info') {
  Vue.notify({
    group: 'foo',
    duration: 4000,
    type: type, // info success warning danger primary rose
    title: title,
    text: text
  })
}
export default {
  toast
}
