import request from '@/utility/request'

function index (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STAFFSTATEMENT_INDEX, data).then(data => {
    callback(data)
  })
}
function list (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STAFFSTATEMENT_LIST, data).then(data => {
    callback(data)
  })
}
function insert (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STAFFSTATEMENT_INSERT, data).then(data => {
    callback(data)
  })
}
export default {
  index, list, insert
}
