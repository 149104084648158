import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
let routes = []

// 遍历包下所有路由文件
const requireContext = require.context('./', true, /\.js$/)
requireContext.keys().forEach(filename => {
  if (filename === './index.js') return
  const routerModule = requireContext(filename)
  routes = [...routes, ...(routerModule.default || routerModule)]
  routes.sort(function (a, b) { return a.id - b.id })
})

const router = new VueRouter({
  routes,
  mode: 'history', // hash history
  linkExactActiveClass: 'active', // 精准匹配
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === '/login/index') {
    next()
  } else {
    const token = localStorage.getItem('Authorization')
    if (token === null || token === '') {
      window.location.href = '/login'
    } else {
      if (from.query.redirect) {
        window.location.href = from.query.redirect
      } else {
        next()
      }
    }
  }
})

export default router
