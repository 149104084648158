<template>
  <div :class="['col-md-'+md,'col-lg-'+lg,'col-sm-'+sm,
  {'ml-auto mr-auto':align == 'center'}]">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RsCol',
  props: {
    lg: {
      type: Number,
      default: 12
    },
    md: {
      type: Number,
      default: 12
    },
    sm: {
      type: Number,
      default: 12
    },
    align: {
      type: String
    }
  },
  data () {
    return {}
  },
  mounted () {
    if (this.aling === 'center') {
      console.log('center')
    }
  }
}
</script>

<style>

</style>
