import request from '@/utility/request'

function index (data, callback) {
  return request.get(process.env.VUE_APP_API_CUSTOM_INDEX, { params: data }).then(data => {
    callback(data)
  })
}
function listSum (data, callback) {
  return request.get(process.env.VUE_APP_API_CUSTOM_LIST_SUM, { params: data }).then(data => {
    callback(data)
  })
}
function list (data, callback) {
  return request.get(process.env.VUE_APP_API_CUSTOM_LIST, { params: data }).then(data => {
    callback(data)
  })
}
/**
 * 用于请求客户按分类统计数据
 */
function count (data, callback) {
  return request.get('/custom/getCustomCount', { params: data }).then(data => {
    callback(data)
  })
}
function insert (data, callback) {
  return request.post(process.env.VUE_APP_API_CUSTOM_INSERT, data).then(data => {
    callback(data)
  })
}
function update (data, callback) {
  return request.post(process.env.VUE_APP_API_CUSTOM_UPDATE, data).then(data => {
    callback(data)
  })
}
function del (data, callback) {
  return request.get(process.env.VUE_APP_API_CUSTOM_DELETE, { params: data }).then(data => {
    callback(data)
  })
}
export default {
  index,
  list,
  listSum,
  count,
  insert,
  update,
  del
}
