import request from '@/utility/request'

function noConnectList (data, callback) {
  return request.get(process.env.VUE_APP_API_FINANCE_PAY_NO_CONNECT_LIST, { params: data }).then(data => {
    callback(data)
  })
}
function addConnect (data, callback) {
  return request.get(process.env.VUE_APP_API_FINANCE_PAY_ADD_CONNECT, { params: data }).then(data => {
    callback(data)
  })
}
function delConnect (data, callback) {
  return request.get(process.env.VUE_APP_API_FINANCE_PAY_DEL_CONNECT, { params: data }).then(data => {
    callback(data)
  })
}

function customMonthPay (data, callback) {
  return request.get(process.env.VUE_APP_API_FINANCE_CUSTOM_MONTH_PAY, { params: data }).then(data => {
    callback(data)
  })
}
function customPay (data, callback) {
  return request.get(process.env.VUE_APP_API_FINANCE_CUSTOM_PAY, { params: data }).then(data => {
    callback(data)
  })
}
function customAllPay (data, callback) {
  return request.get(process.env.VUE_APP_API_FINANCE_CUSTOM_ALL_PAY, { params: data }).then(data => {
    callback(data)
  })
}
function index (data, callback) {
  return request.get(process.env.VUE_APP_API_FINANCE_PAY_INDEX, { params: data }).then(data => {
    callback(data)
  })
}
function insert (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_PAY_INSERT, data).then(data => {
    callback(data)
  })
}
function edit (data, callback) {
  return request.get(process.env.VUE_APP_API_FINANCE_PAY_EDIT, { params: data }).then(data => {
    callback(data)
  })
}
function update (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_PAY_UPDATE, data).then(data => {
    callback(data)
  })
}
function del (data, callback) {
  return request.get(process.env.VUE_APP_API_FINANCE_PAY_DELETE, { params: data }).then(data => {
    callback(data)
  })
}
export default {
  noConnectList, addConnect, delConnect, customMonthPay, customPay, customAllPay, index, insert, edit, update, del
}
