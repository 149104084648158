<template>
  <div class="dataTables_wrapper">
    <table class="table">
        <thead>
            <tr>
                <th v-if="is_index">#</th>
                <th v-for="(value, key) in column" :key="key">{{value}}</th>
                <th class="text-right">
                    操作
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(value, key) in data" :key="key">
                <td v-if="is_index">{{key+1}}</td>
                <td v-for="(v, k) in column" :key="k">
                    {{value[k]}}
                    </td>
                <slot :row="value" :column="key"></slot>
            </tr>
            <!-- 统计行 -->
            <slot name="count"></slot>
        </tbody>
    </table>
</div>
</template>

<script>
export default {
  name: 'RsTable',
  props: {
    is_index: { type: Boolean, default: false },
    data: { type: Array, required: true },
    column: { type: Object, require: true }
  }
}
</script>

<style>

</style>
