export default [
  {
    id: 4,
    path: '/account',
    name: '财务',
    icon: 'widgets',
    component: () => import('@/views/layout/home.vue'),
    redirect: '/finance/index',
    children: [
      {
        path: 'index',
        name: '主页',
        icon: 'home',
        component: () => import('@/views/finance/index.vue')
      },
      {
        path: 'bills',
        name: '账务报表',
        icon: 'attach_money',
        component: () => import('@/views/finance/bills.vue')
      },
      {
        path: 'tag',
        name: '报账主体',
        icon: 'people_outline',
        component: () => import('@/views/finance/tag.vue')
      },
      {
        path: 'log',
        name: '日志',
        icon: 'home',
        component: () => import('@/views/finance/log.vue')
      }
    ]
  }
]
