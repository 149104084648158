import request from '@/utility/request'

function list (data, callback) {
  return request.get(process.env.VUE_APP_API_CUSTOM_PAY_LIST, { params: data }).then(data => {
    callback(data)
  })
}
function insert (data, callback) {
  return request.post(process.env.VUE_APP_API_CUSTOM_PAY_INSERT, data).then(data => {
    callback(data)
  })
}
function index (data, callback) {
  return request.get(process.env.VUE_APP_API_CUSTOM_PAY_INDEX, { params: data }).then(data => {
    callback(data)
  })
}
function update (data, callback) {
  return request.post(process.env.VUE_APP_API_CUSTOM_PAY_UPDATE, data).then(data => {
    callback(data)
  })
}
function del (data, callback) {
  return request.get(process.env.VUE_APP_API_CUSTOM_PAY_DELETE, { params: data }).then(data => {
    callback(data)
  })
}
export default {
  list, insert, index, update, del
}
