<template>
    <ul class="nav">
        <template v-for="(item, index) in nav">
            <router-link :to="item.path" v-if="(!item.children && item.status != false) || item.index == true"
                class="nav-item" tag="li" :key="index">
                <a class="nav-link">
                    <i class="material-icons">{{ item.icon }}</i>
                    <p>{{ item.name }}</p>
                </a>
            </router-link>
            <li v-else-if="item.children && item.status != false" :key="index" class="nav-item">
                <a class="nav-link" data-toggle="collapse" :href="'#' + item.path.substring(1)"
                    :class="{ 'router-link-active': item.url == $route.matched[0].path.substring(1) }">
                    <i class="material-icons">{{ item.icon }}</i>
                    <p> {{ item.name }} <b class="caret"></b> </p>
                </a>
                <div class="collapse" :id="item.path.substring(1)" :class="{ 'show': item.path == $route.matched[0].path }">
                    <ul class="nav">
                        <template v-for="(v, k) in item.children">
                            <router-link v-if="v.status != false" class="nav-item" tag="li" :to="item.path + '/' + v.path"
                                :key="k">
                                <a class="nav-link" href="javascript::(0)">
                                    <i class="material-icons">{{ v.icon }}</i>
                                    <!-- <span class="sidebar-mini"> {{v.title.slice(0,1)}} </span> -->
                                    <span class="sidebar-normal"> {{ v.name }} </span>
                                </a>
                            </router-link>
                        </template>
                    </ul>
                </div>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    name: 'RsNav',
    props: {
        nav: {
            require: true
        }
    }
}
</script>

<style>
a.router-link-active {
    background: #00bcd4;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 188 212 / 40%);
}</style>
