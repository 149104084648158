<template>
  <button :class="[
  'btn',`btn-${color}`,
  {'btn-round':is_round},
  {'btn-fab':is_fab},
  {'btn-sm':is_sm},
  {'btn-lg':is_lg},
  {'btn-link':is_link}
  ]"
  :type="type"
  @click="handleClick"
  >
  <span class="btn-label" v-if="$slots.left">
    <i class="material-icons"><slot name="left"></slot></i>
  </span>
  <slot v-if="$slots.default"></slot>
  <span class="btn-label" v-if="$slots.right">
    <i class="material-icons"><slot name="right"></slot></i>
  </span>
  </button>
<!-- type=“”颜色类型 is_round圆角  is_fab圆角基础上变圆型 is_sm缩小 is_lg放大 is_link无外框底色-->
<!-- primary/success/warning/danger/info -->
<!-- v-slot:left左侧插槽 v-slot:right右侧插槽-->
</template>

<script>
export default {
  name: 'RsButton',
  props: {
    type: { type: String, default: 'button' },
    color: { type: String, default: 'default' },
    is_round: { type: Boolean, default: false },
    is_fab: { type: Boolean, default: false },
    is_sm: { type: Boolean, default: false },
    is_lg: { type: Boolean, default: false },
    is_link: { type: Boolean, default: false }
  },
  methods: {
    handleClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>
