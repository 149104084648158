var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
  'btn',("btn-" + _vm.color),
  {'btn-round':_vm.is_round},
  {'btn-fab':_vm.is_fab},
  {'btn-sm':_vm.is_sm},
  {'btn-lg':_vm.is_lg},
  {'btn-link':_vm.is_link}
  ],attrs:{"type":_vm.type},on:{"click":_vm.handleClick}},[(_vm.$slots.left)?_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"material-icons"},[_vm._t("left")],2)]):_vm._e(),(_vm.$slots.default)?_vm._t("default"):_vm._e(),(_vm.$slots.right)?_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"material-icons"},[_vm._t("right")],2)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }