import request from '@/utility/request'

function list (data, callback) {
  return request.get(process.env.VUE_APP_API_BILLS_IMAGE_LIST, { params: data }).then(data => {
    callback(data)
  })
}
function insert (data, callback) {
  return request.post(process.env.VUE_APP_API_BILLS_IMAGE_INSERT, data).then(data => {
    callback(data)
  })
}
function del (data, callback) {
  return request.get(process.env.VUE_APP_API_BILLS_IMAGE_DELETE, { params: data }).then(data => {
    callback(data)
  })
}
export default {
  list, insert, del
}
