export default [
  {
    path: '/login',
    status: false,
    component: () => import('@/views/layout/login.vue'),
    redirect: '/login/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/login/index.vue')
      }
    ]
  }
]
