// import swal from './tools/swal'
// import notify from './tools/notify'
// import upload from './tools/upload'
// export default {
//   swal,
//   notify,
//   upload
// }
const requireContext = require.context('./tools', true, /\.js$/)
var module = {}
requireContext.keys().forEach(filename => {
  const name = filename.replace(/(\.\/|\.js)/g, '')
  module[name] = requireContext(filename).default
})
export default module
