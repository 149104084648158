import { render, staticRenderFns } from "./RsTabs.vue?vue&type=template&id=df676af4&scoped=true&"
import script from "./RsTabs.vue?vue&type=script&lang=js&"
export * from "./RsTabs.vue?vue&type=script&lang=js&"
import style0 from "./RsTabs.vue?vue&type=style&index=0&id=df676af4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../sailing_vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df676af4",
  null
  
)

export default component.exports