<template>
    <rs-row>
      <rs-col :lg="size" :md="size" >
        <rs-form :model="data" ref="Form" @submit.native="submit" style="max-height:90vh;overflow:auto">
            <rs-form-item v-for="(value,index) in type"
                :label="value.label"
                :prop="value.type"
                :class="{'is-filled':data[value.type]}"
                :rules="value.rules"
                :key="index"
            >
                <rs-input v-model="data[value.type]" :disabled="value.active" :type="value.inputType"></rs-input>
                <rs-button
                    v-if="value.active"
                    class="edit"
                    color="success"
                    @click="
                        $refs.rskey.handle(data[value.type],value.type),
                        size=6,
                        keyList=value.keyList
                    "
                    is_sm
                >编辑</rs-button>
            </rs-form-item>
            <rs-form-item>
                <rs-button type="submit" color="info">提交</rs-button>
            </rs-form-item>
        </rs-form>
      </rs-col>
        <rs-col v-show="size == 6" :lg="size" :md="size">
            <rs-key ref="rskey" @updateKey="updateKey" :keyList="keyList"></rs-key>
        </rs-col>
    </rs-row>
</template>

<script>
export default {
  name: 'RsForms',
  props: {
    callback: {
      type: Function
    },
    type: {
      type: Array
    },
    data: {
      type: Object
    }
  },
  data () {
    return {
      size: 12,
      keyList: []
    }
  },
  methods: {
    submit (e) {
      e.preventDefault()
      this.$refs.Form.validate(isValidate => {
        if (isValidate) {
          this.callback(this.data)
        }
      })
    },
    updateKey (value, key) {
      this.data[key] = value
      this.size = 12
      // 调用验证
      this.$refs.Form.$children.forEach(element => {
        if (element.prop === key) {
          element.validate()
        }
      })
    }
  }
}
</script>

<style>

</style>
