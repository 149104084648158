// import personal from './personal'
// import company from './company'
// import companyProduct from './companyProduct'
// import companyProductColor from './companyProductColor'
// import custom from './custom'
// import customImage from './customImage'
// import customMessage from './customMessage'
// import customPay from './customPay'
// import customOrder from './customOrder'
// import customOrderSum from './customOrderSum'
// import customTask from './customTask'
// import staff from './staff'
// import staffStatement from './staffStatement'
// import billImage from './billImage'
// import billsImage from './billsImage'
// import statement from './statement'
// import statements from './statements'
// import pay from './pay'
// export default {
//   company,
//   companyProduct,
//   companyProductColor,
//   custom,
//   customImage,
//   customMessage,
//   customPay,
//   customOrder,
//   customOrderSum,
//   customTask,
//   staff,
//   staffStatement,
//   billImage,
//   billsImage,
//   statement,
//   statements,
//   pay,
//   personal
// }

const requireContext = require.context('./api/', true, /\.js$/)
var module = {}
requireContext.keys().forEach(filename => {
  const name = filename.replace(/(\.\/|\.js)/g, '')
  module[name] = requireContext(filename).default
})
export default module
