<template>
    <div class="tab-pane" :class="{active:name===$parent.value}">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'RsTabPane',
  props: ['label', 'name', 'icon']
}
</script>
