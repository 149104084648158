<template>
  <div v-if="isShow">
    <div>
      <rs-input v-model="value" type="text"></rs-input>
    </div>
    <div>
      <rs-button color="info" @click="add(1)"> 1 </rs-button>
      <rs-button color="info" @click="add(2)"> 2 </rs-button>
      <rs-button color="info" @click="add(3)"> 3 </rs-button>
      <rs-button color="info" @click="delOne"> <i class="material-icons">backspace</i> </rs-button>
    </div>
    <div>
      <rs-button color="info" @click="add(4)"> 4</rs-button>
      <rs-button color="info" @click="add(5)"> 5 </rs-button>
      <rs-button color="info" @click="add(6)"> 6 </rs-button>
      <rs-button color="info" @click="value=''"> 清空 </rs-button>
    </div>
    <div>
      <rs-button color="info" @click="add(7)"> 7 </rs-button>
      <rs-button color="info" @click="add(8)"> 8 </rs-button>
      <rs-button color="info" @click="add(9)"> 9 </rs-button>
      <rs-button color="success" @click="done"> <i class="material-icons">done</i> </rs-button>
    </div>
    <div>
      <rs-button color="info" @click="add('.')"> 。 </rs-button>
      <rs-button color="info" @click="add(0)"> 0 </rs-button>
    </div>
    <div>
      <rs-button v-for="list in keyList" :key="list" color="info" @click="value=list" is_sm> {{list}} </rs-button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'RsKey',
  props: {
    keyList: {
      type: Array
    }
  },
  data: function () {
    return {
      value: '',
      key: '',
      isShow: false
    }
  },
  methods: {
    handle(value, key) {
      this.value = value
      this.key = key
      this.isShow = true
    },
    add(value) {
      if (this.value === '0') {
        this.value = value
      } else {
        this.value = this.value + '' + value
      }
    },
    delOne() {
      this.value = this.value + ''
      this.value = this.value.substring(0, this.value.length - 1)
    },
    done() {
      this.$emit('updateKey', this.value, this.key)
      this.isShow = false
    }
  }
}
</script>

<style lang="scss">
.bmd-form-group button.edit {
  position: absolute;
  right: 0;
  top: 0
}

.edit {
  position: absolute;
  right: 0px;

  .material-icons {
    font-size: 16px;
  }

  .nav-link i {
    color: #888 !important;
  }

  .nav-link {
    padding-right: 0 !important;
    padding-left: 10px !important;
  }
}
</style>
