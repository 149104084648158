<template>
  <form>
      <slot></slot>
  </form>
</template>

<script>
export default {
  name: 'RsForm',
  provide () {
    return {
      form: this
    }
  },
  props: {
    model: { type: Object }
  },
  methods: {
    // 全局表单校验
    validate (cb) {
      const tasks = this.$children
        .filter(item => item.prop) // 只留下有rules属性的item进行验证
        .map(item => item.validate())
      const flag = true
      Promise.all(tasks)
        .then(() => cb(flag))
        .catch(() => cb(null))
    }
  }
}
</script>

<style>

</style>
