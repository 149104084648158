import request from '@/utility/request'

/**
 * 用于用户登陆
 */
function login (data, callback) {
  return request.get(process.env.VUE_APP_API_LOGIN, { params: data }).then(data => {
    callback(data)
  })
}
/**
 * 用于获取登陆用户信息
 */
function index (data, callback) {
  return request.get(process.env.VUE_APP_API_MEMBER_INDEX, { param: data }).then(data => {
    callback(data)
  })
}
export default {
  login,
  index
}
