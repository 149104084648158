import request from '@/utility/request'

function info (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STAFF_INFO, data).then(data => {
    callback(data)
  })
}
function type (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STAFF_TYPE, data).then(data => {
    callback(data)
  })
}
function list (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STAFF_LIST, data).then(data => {
    callback(data)
  })
}
function insert (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STAFF_INSERT, data).then(data => {
    callback(data)
  })
}
function edit (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STAFF_INDEX, data).then(data => {
    callback(data)
  })
}
function update (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STAFF_UPDATE, data).then(data => {
    callback(data)
  })
}
function del (data, callback) {
  return request.post(process.env.VUE_APP_API_FINANCE_STAFF_DELETE, data).then(data => {
    callback(data)
  })
}
export default {
  info, type, list, insert, edit, update, del
}
