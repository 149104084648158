import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Cascader, Calendar } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import VCharts from 'v-charts'

// import RedscnUi from './components/redsUi/index.js'
import RedscnUi from 'reds_one_ui'
import api from './utility/api.js'
import tools from './utility/tools.js'
// 引入组件
Vue.use(RedscnUi)
Vue.use(VCharts)
Vue.use(Cascader)
Vue.use(Calendar)
Vue.prototype.$tools = tools // 原型挂载
Vue.prototype.$api = api // 原型挂载
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
