<template>
  <div class="card">
        <div class="card-header card-header-info card-header-icon">
          <rs-row>
            <rs-col :md="4" :lg="4" :sm="4">
              <div class="card-icon">
                  <i class="material-icons">{{icon}}</i>
              </div>
              <div class="card-title">
                <span class="h4">{{title}}</span>
              </div>
            </rs-col>
            <rs-col :md="8" :lg="8" :sm="8">
              <div class="card-title text-right">
                <slot name="tools"></slot>
              </div>

            </rs-col>
          </rs-row>
        </div>
        <div class="card-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'RsCard',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    icon: {
      type: String,
      default: 'home'
    }
  }
}
</script>
