<template>
<div class="chat-activity-list">
    <!--加载消息-->
    <div class="chat-element" :class="{'chat-right':msg.rule}" v-for="(msg,index) in data" :key="index">
        <img src="" class="float-left">
        <div class="media-body">
            <small>{{msg.createTime}}</small>
            <strong>{{msg.username}}</strong>
            <div>
                {{msg.message}}
                <a v-if="msg.rule" href="javascript:(0)" @click="messageDelete(msg.id, index)" class="float-right chat-message-del" style="color:#999">
                    <i class="material-icons">delete</i>
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'RsChat',
  props: {
    data: { type: Array }
  },
  methods: {
    messageDelete (id, item) {
      this.$emit('messageDelete', id, item)
    }
  }
}
</script>

<style>
.chat-element{
    margin-bottom: 10px;
}
.chat-element small{
    float: right;
}
.chat-element img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin:0 10px;
}
.media-body{
    margin-left: 85px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
    border-radius: 6px;
    padding: 10px;
    position: relative;
}
.media-body::before{
    width: 20px;
    margin-left:99px;
    content:' ';
    position: absolute;
    top: 12px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 14px solid transparent;
    border-left-width: 0;
    border-right-width: 14px;
    left: -119px;
    right: auto;
}
.chat-right img{
    float:right !important;
}
.chat-right small{
    float:none;
}
.chat-right strong{
    float: right;
}
.chat-right .media-body{
    margin-left:0;
    margin-right:85px;
}
.chat-right .media-body::before{
    border-left-width: 14px;
    border-right-width: 0px;
    left: auto;
    right: -20px;
}
.chat-element:hover .chat-message-del{
    display: block;
}
.chat-message-del{
    display: none;
    font-size: 18px;
}
</style>
