export default [
  {
    id: 1,
    path: '/',
    name: '首页',
    icon: 'home',
    index: true,
    component: () => import('@/views/layout/home.vue'),
    redirect: '/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/Home.vue')
      }
    ]
  }
]
