import request from '@/utility/request'

function index (data, callback) {
  return request.get(process.env.VUE_APP_API_CUSTOM_ORDER_SUM_INDEX, { params: data }).then(data => {
    callback(data)
  })
}
function list (data, callback) {
  return request.get(process.env.VUE_APP_API_CUSTOM_ORDER_SUM_LIST, { params: data }).then(data => {
    callback(data)
  })
}
function insert (data, callback) {
  return request.post(process.env.VUE_APP_API_CUSTOM_ORDER_SUM_INSERT, data).then(data => {
    callback(data)
  })
}
function update (data, callback) {
  return request.post(process.env.VUE_APP_API_CUSTOM_ORDER_SUM_UPDATE, data).then(data => {
    callback(data)
  })
}
function del (data, callback) {
  return request.get(process.env.VUE_APP_API_CUSTOM_ORDER_SUM_DELETE, { params: data }).then(data => {
    callback(data)
  })
}
export default {
  list, insert, index, update, del
}
