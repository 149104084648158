<template>
  <select :value="value"  @input="onInput" class="form-control" v-bind="$attrs">
    <slot></slot>
  </select>
</template>

<script>
export default {
  name: 'RsSelect',
  inheritAttrs: false,
  props: {
    value: {}
  },
  methods: {
    onInput (e) {
      this.$emit('input', e.target.value)
      // 通知父组件校验
      this.$parent.$emit('validate')
    }
  },
  mounted () {
    if (this.value) {
      this.$emit('input', this.value)
    }
  }
}
</script>
