<template>
  <div>
    <div v-if="images">
      <div class="row">
        <div class="col-md-4" v-for="(value,index) in images" :key="index">
          <div class="card card-product">
            <div class="card-header card-header-image" data-header-animation="true">
              <a href="#pablo">
                <img class="img" :src="value.img">
              </a>
            </div>
            <div class="card-body">
              <div class="card-actions text-center">
                <button type="button" class="btn btn-danger btn-link fix-broken-card">
                  <i class="material-icons">build</i> Fix Header!
                </button>
                <button type="button" class="btn btn-default btn-link" rel="tooltip" data-placement="bottom" title="View">
                  <i class="material-icons">art_track</i>
                </button>
                <button type="button" class="btn btn-success btn-link" rel="tooltip" data-placement="bottom" title="Edit">
                  <i class="material-icons">edit</i>
                </button>
                <button type="button" class="btn btn-danger btn-link" rel="tooltip" data-placement="bottom" title="Remove">
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="float-right">
      <span class="btn btn-info btn-round btn-file">
        <span class="fileinput-new">选择图片</span>
        <input type="file" @change="httpRequest">
      </span>
      <rs-button is_round color="info" @click="UploadFile">上传</rs-button>
    </div>
  </div>
</template>
<script>
import OSS from 'ali-oss'
// 引入oss
export default {
  name: 'RsUpload',
  data () {
    return {
      images: [],
      clientInfo: {
        region: 'oss-cn-hangzhou',
        secure: true,
        accessKeyId: '4aqsQhO2yPy8k87S',
        accessKeySecret: 'C9kg2dgvQvcUnpHVFsiKL7dQsq9Zlq',
        bucket: 'redscn'
      }
    }
  },
  methods: {
    toUpload (img) {
      const files = img.target.files
      console.log(files)
      if (this.images.length + files.length > 20) {
        this.$notify({
          group: 'foo',
          duration: 8000,
          type: 'warning', // info success warning danger primary rose
          title: '警告',
          text: '最多只能选择六个文件!'
        })
        return
      }
      for (var i = 0; i < files.length; i++) {
        const _this = this;
        (function () {
          _this.images.push(
            {
              name: files[i].name,
              size: files[i].size / 1024 + 'KB',
              blob: window.URL.createObjectURL(files[i]),
              img: ''
            }
          )
          var reader = new FileReader()
          const key = _this.images.length - 1
          reader.readAsDataURL(files[i])
          reader.onload = () => {
            _this.images[key].img = reader.result
          }
        })(i)
      }
    },
    UploadFile () {
      const client = new OSS(this.clientInfo)
      // 获得当前日期目录
      var date = new Date()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var time =
            date.getFullYear() +
            '-' +
            (month < 10 ? '0' + month : month) +
            '-' +
            (day < 10 ? '0' + day : day)
      for (let key = this.images.length - 1; key > -1; key--) {
        // 获得扩展名
        var index = this.images[key].name.lastIndexOf('.')
        var suffix = this.images[key].name.substr(index + 1)
        // 获得随机名称
        const data = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
        let nums = ''
        for (let i = 0; i < 8; i++) {
          const r = parseInt(Math.random() * 52, 10)
          nums += data[r]
        }
        // 组合名称 如果是image类型则重新命名，如果是其它文件则保留命名
        const objectKey = 'Baihui/' + time + '/' + nums + '.' + suffix
        if (this.put(client, this.images[key].blob, objectKey)) {
          this.images.splice(key, 1)
        }
      }
    },
    async put (client, fileurl, objectKey, _this) {
      try {
        const buffer = new OSS.Buffer(fileurl)
        // object-name可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        await client.put(objectKey, buffer).then((res) => {
          if (res.res.status === 200) {
            this.$notify({
              group: 'foo',
              duration: 8000,
              type: 'success', // info success warning danger primary rose
              title: '成功',
              text: res.name + '已上传'
            })
            this.$parent.$parent.insert(res)
            return true
          } else {
            return false
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    httpRequest (file) {
      console.log(file)
      // 生成上传oss
      const store = new OSS(this.clientInfo)
      // 路径
      const path = 'home/' + file.target.files[0].name
      store
        .multipartUpload(
          path,
          file.target.files[0],
          {
            // 进度
            progress: this.onProgress
          }
        )
        .then(result => {
          // 生成访问地址
          const url = store.signatureUrl(result.name, {
            expires: 3153600000
          })
          console.log(url)
        })
    }
  }
}
</script>
