<template>
  <transition :name="right?'dialog-right':'dialog'">
    <div class="modal" v-if="visible" @click.self="close">
      <div class="modal-dialog modal-lg" :class="{'modal-right':right}">
          <div class="modal-content">
              <div class="modal-header">
                  <rs-button class="close" @click="close" is_link>
                      <i class="material-icons">close</i>
                  </rs-button>
                  <h4 class="modal-title">{{title}}</h4>
              </div>
              <div class="modal-body" style="overflow: auto;max-height:calc(100vh)">
                  <slot></slot>
              </div>
          </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'RsDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '标题'
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style>
.modal{
    display: block;
    background-color: rgb(0,0,0,0.4);
}
.dialog-enter-active{
  animation: fade .3s;
}
.dialog-leave-active{
  animation: fade .3s reverse;
}
@keyframes fade {
    0%{
        opacity: 0;
        transform: translateY(-20px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.dialog-right-enter-active{
  animation: right .3s;
}
.dialog-right-leave-active{
  animation: right .3s reverse;
}
@keyframes right {
    0%{
        opacity: 0;
        transform: translateX(20px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
.modal-right {
    margin-top: 0px !important;
    height: calc(100vh);
    margin-right: 0px;
    width: 40%;
}
.modal-right .modal-content {
    height: 100%;
}
</style>
