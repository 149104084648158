import OSS from 'ali-oss'
function alioss (file, pathUrl, callback) {
  const clientInfo = {
    region: 'oss-cn-hangzhou',
    secure: true,
    accessKeyId: '4aqsQhO2yPy8k87S',
    accessKeySecret: 'C9kg2dgvQvcUnpHVFsiKL7dQsq9Zlq',
    bucket: 'redscn'
  }
  // const file = images.target.files[0]
  // 生成上传oss
  const store = new OSS(clientInfo)
  // 路径
  var date = new Date()
  var month = date.getMonth() + 1
  var day = date.getDate()
  var time =
    date.getFullYear() +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (day < 10 ? '0' + day : day)
    // 获得扩展名
  var index = file.name.lastIndexOf('.')
  var suffix = file.name.substr(index + 1)
  // 获得随机名称
  const data = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  let nums = ''
  for (let i = 0; i < 8; i++) {
    const r = parseInt(Math.random() * 52, 10)
    nums += data[r]
  }
  // 组合名称 如果是image类型则重新命名，如果是其它文件则保留命名
  const path = pathUrl + time + '/' + nums + '.' + suffix
  // 上传
  store
    .multipartUpload(
      path,
      file,
      {
        // 进度
        progress: this.onProgress
      }
    )
    .then(result => {
      // 生成访问地址
      callback(result)
      // const url = 'https://redscn.oss-cn-hangzhou.aliyuncs.com/' + result.name
      // this.$api.customImage.insert({ cid: this.$route.query.id, img: url }, function (data) {
      // this.customImage.push(data.data)
      // }.bind(this))
    })
}
export default {
  alioss
}
