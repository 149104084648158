<template>
  <div id="app">
    <notifications group="foo" classes="notifications">
      <template slot="body" slot-scope='props'>
        <div class="alert" :class="'alert-' + props.item.type">
          <a class="close" @click="props.close">
            <i class="material-icons">close</i>
          </a>
          <span>
            <b> {{ props.item.title }} - </b> {{ props.item.text }}
          </span>
        </div>
      </template>
    </notifications>
    <router-view></router-view>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  created() {
    $('html').on('click', 'a', function (e) {
      if ($(this).data('toggle') === 'collapse') {
        if ($($(this).attr('href'))[0].classList.contains('show')) {
          $($(this).attr('href'))[0].classList.remove('show')
        } else {
          $($(this).attr('href'))[0].classList.add('show')
        }
      }
    })
  }
}
</script>
<style>
.vue-notification-group {
  right: 10px !important;
  top: 10px !important;
}
</style>
