import './css/index.scss'
// 初始化
const component = {
  install: function (Vue) {
    const requireContext = require.context('./components/', true, /\.vue$/)
    requireContext.keys().forEach(filename => {
      const reqCom = requireContext(filename)
      Vue.component(reqCom.default.name, reqCom.default || reqCom)
    })
  }
}
// 暴露组件
export default component
