import axios from "axios";
import router from "@/router/index";
// import store from '@/store/index.js'

var baseURL = null;
if (
  (window.location.hostname === "localhost") |
  (window.location.hostname === "127.0.0.1")
) {
  baseURL = "https://v2.sailinging.top/api";
} else {
  baseURL = window.location.origin + "/api";
}

const request = axios.create({
  baseURL: baseURL,
  timeout: 12000,
  headers: {
    "Content-Type": "application/json", // 发送数据格式
    Accept: "application/json" // 接收数据格式
  }
});
// 请求拦截器
request.interceptors.request.use(
  config => {
    // config.headers.token = store.state.token
    const token = localStorage.getItem("Authorization");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// 响应拦截器
request.interceptors.response.use(
  response => {
    const request = response.data;
    switch (request.code) {
      case 200:
        return request;
      case 303: // Token失效或超时
        localStorage.clear();
        router.replace({
          path: "/login",
          query: { redirect: router.currentRoute.fullPath }
        });
        break;
      case 304: // 用户名不存在
        console.log(request.msg);
        break;
      default:
        console.log("响应拦截后,情况不明");
        break;
    }
  },
  error => {
    const { response } = error;
    if (response) {
      // 收到结果
      switch (response.status) {
        case 401: // 未登陆
          break;
        case 403: // TOKEN过期
          break;
        case 404: // 找不到页面
          break;
        case 500: // 服务器失联
          break;
      }
    } else {
      // 未收到结果
      if (!window.navigator.onLine) {
        // 断网处理
        return;
      }
      return Promise.reject(error);
    }
  }
);
export default request;
