<template>
  <div class="row">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RsRow',
  data () {
    return {}
  }
}
</script>

<style>

</style>
